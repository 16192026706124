<template>
  <div>
    <b-modal :no-close-on-backdrop="true" id="add-student-modal" ref="add-student-modal" centered hide-footer>
      <validation-observer ref="addStudentForm">
        <b-form class="auth-register-form mt-2">
          <div>
            <b-form-group label="First name" label-for="register-firstname">
              <validation-provider #default="{ errors }" name="Firstname" rules="required">
                <b-form-input id="register-firstname" name="register-firstname"
                  :state="errors.length > 0 ? false : null" placeholder="First name" v-model="student_form.firstname" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Last name" label-for="register-lastname">
              <validation-provider #default="{ errors }" name="Lastname" rules="required">
                <b-form-input id="register-lastname" name="register-lastname" :state="errors.length > 0 ? false : null"
                  placeholder="Last Name" v-model="student_form.lastname" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Phone number" label-for="register-mobile">
              <validation-provider #default="{ errors }" name="Mobile Number" rules="required">
                <b-form-input id="register-mobile" type="number" name="register-mobile"
                  :state="errors.length > 0 ? false : null" placeholder="+91XXXXXXXXXX" v-model="student_form.mobile" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Email" label-for="register-email">
              <validation-provider #default="{ errors }" name="Email Address" rules="required">
                <b-form-input id="register-email" :state="errors.length > 0 ? false : null" name="register-email"
                  v-model="student_form.email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label-for="register-password" label="Password">
              <validation-provider #default="{ errors }" name="Email Address" rules="required">
                <b-input-group class="input-group-merge">
                  <b-form-input id="register-password" class="form-control-merge rp_padding"
                    :state="errors.length > 0 ? false : null" name="register-password" :type="passwordFieldType"
                    placeholder="············" v-model="student_form.password" />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="text-right">
            <b-button @click="closeModal" variant="outline-danger mr-2">
              Close
            </b-button>
            <b-button variant="outline-primary" type="submit" @click.prevent="addStudent">
              Submit
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-card>
      <b-col class="m-2 w-100">
        <!-- Table Filters -->

        <b-row class="mr-3 mb-2 align-items-end">
          <b-col class="col-md-7">
            <b-form-input v-model="filters.search" class="d-inline-block mr-1" placeholder="Search..."
              @input="onSearch" />
          </b-col>
          <b-col>
            <label>Per Page</label>
            <v-select v-model="pagination.perPage" :options="pagination.perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block w-100" @input="onSearch" />
          </b-col>
          <!-- <b-col>
            <label>Services</label>
            <v-select v-model="filters.service_id" :options="services_list" :clearable="false" :reduce="(item) => item.service_id" label="service_name"
              class="per-page-selector d-inline-block w-100" @input="onSearch" />
          </b-col> -->
          <!-- Per Page -->

          <!-- Status -->
          <b-col class="d-flex align-items-start justify-content-end flex-column mb-1 mb-md-0">
            <label>Status</label>
            <v-select v-model="filters.student_status" :options="student_status_options" :clearable="true"
            item-text="label" :reduce="(item) => item.value" class="per-page-selector d-inline-block w-100"
            @input="onSearch" />
          </b-col>
          <!-- Status -->

        </b-row>
        <b-row class="mr-3 mb-2 align-items-end">
          <!-- <b-col
            class="d-flex align-items-start justify-content-center flex-column mb-1 mb-md-0 p-0"
          >
            <label>Per Page</label>
            <v-select
              v-model="pagination.perPage"
              :options="pagination.perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block w-100"
              @input="onSearch"
            />
          </b-col> -->

          <!-- Status -->
         
          <!-- Conversion -->
          <b-col class="d-flex mb-1 mb-md-0 align-items-start justify-content-center flex-column"
            v-if="!['operations', 'visa'].includes(user_type)">
            <label>Conversion</label>
            <v-select v-model="filters.conversion" :options="conversion_status_options" :clearable="true"
              item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>

          <!-- Counsellor Wise Filter -->
          <b-col class="d-flex mb-1 mb-md-0 align-items-start justify-content-center flex-column" v-else>
            <label>Counselor</label>
            <v-select v-model="filters.counselor_id" :options="counselor_options.map((counselor) => ({
              value: counselor.user_id,
              label: `${counselor.user_firstname} ${counselor.user_lastname}`,
            }))
              " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>
          <b-col class="d-flex mb-1 mb-md-0 align-items-start justify-content-center flex-column" v-if="
            [
              'operations_manager',
            ].includes(user_type)
          ">
            <label>Counselor</label>
            <v-select v-model="filters.counselor_id" :options="op_counselors.map((counselor) => ({
              value: counselor.user_id,
              label: `${counselor.user_firstname} ${counselor.user_lastname}`,
            }))
              " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>

          <b-col class="d-flex mb-1 mb-md-0 align-items-start justify-content-center flex-column" v-if="
            [
              'operations_manager','counselor','operations','visa'
            ].includes(user_type)
          ">
            <label>Franchise</label>
            <v-select v-model="filters.franchise_id" :options="op_franchises.map((franchise) => ({
              value: franchise.user_id,
              label: `${franchise.user_firstname} ${franchise.user_lastname}`,
            }))
              " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>

          <b-col class="d-flex mb-1 mb-md-0 align-items-start justify-content-center flex-column" v-if="
            [
              'operations_manager','counselor','operations','visa'
            ].includes(user_type)
          ">
            <label>Associate</label>
            <v-select v-model="filters.agent_id" :options="op_agents.map((agent) => ({
              value: agent.user_id,
              label: `${agent.user_firstname} ${agent.user_lastname}`,
            }))
              " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>

        <b-col class="d-ex mb-1 mb-md-0 align-items-start justify-content-center flex-column" v-if="
            [
              'operations_manager',
            ].includes(user_type)
          ">
            <label>Operations</label>
            <v-select v-model="filters.operations_id" :options="op_operations.map((counselor) => ({
              value: counselor.user_id,
              label: `${counselor.user_firstname} ${counselor.user_lastname}`,
            }))
              " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>

          <b-col class="d-flex mb-1 mb-md-0 align-items-start justify-content-center flex-column" v-if="
            [
              'operations_manager',
            ].includes(user_type)
          ">
            <label>Visa</label>
            <v-select v-model="filters.visa_id" :options="op_visa.map((counselor) => ({
              value: counselor.user_id,
              label: `${counselor.user_firstname} ${counselor.user_lastname}`,
            }))
              " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>

          <b-col v-if="
            [
              // 'operations',
              'counselor',
              // 'franchise',
              'student_support',
            ].includes(user_type)
          " class="d-flex mb-1 mb-md-0 align-items-start justify-content-center flex-column">
            <label>Lead Type</label>
            <v-select v-model="leadType" :options="leadTypeOptions" :clearable="true" item-text="label"
              :reduce="(item) => item.value" class="per-page-selector d-inline-block w-100" @input="() => {
                leadSource = null
                onSearch()
              }" />
          </b-col>
          <b-col v-if="
            [
              // 'operations',
              'counselor',
              // 'franchise',
              'student_support',
            ].includes(user_type)
          " class="d-flex align-items-start justify-content-center flex-column mb-1 mb-md-0">
            <label>Lead Source</label>
            <v-select v-model="leadSource" :disabled="leadType ? false : true"
              :options="getCurrentSourceOptions(leadType)" :clearable="true" item-text="label"
              :reduce="(item) => item.value" class="per-page-selector d-inline-block w-100" @input="onSearch" />
          </b-col>

          <b-col class="d-flex align-items-start justify-content-center flex-column mb-1 mb-md-0">
            <label>Assigned on Date Range</label>
            <flat-pickr id="assigned_on" placeholder="Select Date Range" v-model="dateRange" class="form-control"
              :config="{
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: 'd-m-Y',
                allowInput: true,
                mode: 'range',
              }" />
          </b-col>
          <b-col class="d-flex align-items-start justify-content-center flex-column mb-1 mb-md-0">
            <button class="btn btn-primary w-100" @click="resetFilters">
              Reset
            </button>
          </b-col>
        </b-row>
        <b-row cols="12 mb-2 d-flex justify-content-end">
          <b-col md="2" class="d-flex align-items-end justify-content-center mb-1 mb-md-0 pr-2">
            <!-- <button
              class="btn btn-primary mr-1 w-100"
              @click="getAssignedStudentsList()"
            >
              Search
            </button> -->
            <!-- <button class="btn btn-primary w-100" @click="resetFilters">
              Reset
            </button> -->
          </b-col>
        </b-row>
      </b-col></b-card>
    <b-card class="mb-0">
      <div v-if="['agent', 'franchise'].includes(user_type)" class="m-2">
        <b-button variant="outline-primary" v-b-modal.add-student-modal>Add Student</b-button>
      </div>

      <!-- Student List -->
      <b-table id="StudentsListTableId" ref="refStudentsListTable" class="position-relative" :items="studentsList"
        responsive :fields="tableColumns" primary-key="student_user_id" show-empty
        empty-text="No matching records found" @row-clicked="row_clicked" selectable>

        <template #cell(user_name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="FILESURL + data.item.user_profile_image" />
            </template>
            <p>
              {{ data.item.user_name }}
            </p>
          </b-media>
        </template>

        <template #cell(user_mobile)="data">
          <div style="width: max-content">
            {{ data.value }}
          </div>
        </template>

        <template #cell(student_applications)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ student_applications_count(data.item.applied_course_ids) }}
            </span>
          </div>
        </template>

        <template #cell(active_applications)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{
                student_applications_count(data.item.active_applied_course_ids)
              }}
            </span>
          </div>
        </template>
        f5f5f5
        <template #cell(student_status)="data">
          <div class="text-nowrap" style="width: max-content">
            <span v-if="['counselor', 'visa', 'operations'].includes(user_type)">
              <b-form-select class="text-capitalize" :options="['hold', 'joined', 'active', 'inactive']"
                :value="data.item.student_status" @input="setStudentStatus($event, data.item)" />
            </span>
            <span v-else class="align-text-top text-capitalize">
              {{ data.item.student_status }}
            </span>
          </div>
        </template>

        <template #cell(conversion_date)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ conversion_date_fn(data.item.conversion_date) }}
            </span>
          </div>
        </template>

        <template #cell(university_commission)="data">
          <div class="text-nowrap">
            <b-form-input v-model="data.item.university_commission"
              @input="onType(data.item.student_user_id, data.item.university_commission, data.item.franchise_commission)" />
          </div>
        </template>

        <!-- Template cell for franchise_commission -->
        <template #cell(franchise_commission)="data">
          <div class="text-nowrap">
            <b-form-input v-model="data.item.franchise_commission"
              @input="onType(data.item.student_user_id, data.item.university_commission, data.item.franchise_commission)" />
          </div>
        </template>




        <template #cell(assigned_staff)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.assigned_staff_name }}
            </span>
          </div>
        </template>

        <template #cell(operations_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.operations_name }}
            </span>
          </div>
        </template>

        <template #cell(visa_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.visa_name }}
            </span>

          </div>
        </template>

        <template #cell(student_source_type)="data">
          <div class="text-nowrap" style="width: max-content">
            <v-select v-if="['counselor'].includes(user_type)" v-model="data.item.source_type"
              :options="leadTypeOptions" :clearable="true" item-text="label" :reduce="(item) => item.value"
              @input.prevent="
                {
                saveLead(
                  data.item.student_user_id,
                  data.item.application_limit,
                  data.item.source_type
                );
                // handleLeadSourceOptions();
              }
                " class="per-page-selector d-inline-block w-100" style="min-width: 200px" />
            <p v-else>
              {{ getLeadTypeFromKey(data.item.source_type) }}
            </p>
          </div>
        </template>

        <template #cell(student_source)="data">
          <div class="text-nowrap" style="width: max-content">
            <v-select :value="data.item.source_user_id
              ? data.item.source_user_id
              : data.item.source
              " v-if="['counselor'].includes(user_type)" :disabled="data.item.source_type ? false : true"
              :options="getCurrentSourceOptions(data.item.source_type)" :clearable="true" item-text="label"
              style="min-width: 200px" :reduce="(item) => item.value" @input.prevent="(value) =>
                saveLead(
                  data.item.student_user_id,
                  data.item.application_limit,
                  data.item.source_type,
                  value
                )
                " />
            <p v-else>
              {{
                data.item.source_user_id
                  ? data.item.source_user
                  : getDirectSourceByValue(data.item.source)
              }}
            </p>
          </div>
        </template>
        <!-- <template #cell(creator)="data">
          <b-media vertical-align="center" style="width: max-content;">
            {{
              data.item.creator_user_id
                ? data.item.creator_firstname + " " + data.item.creator_lastname
                : "website"
            }}
          </b-media>
        </template> -->
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted"> {{ totalStudentCount }} entries </span>
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination aria-controls="StudentsListTableId" v-model="pagination.currentPage"
              :total-rows="totalStudentCount" :per-page="pagination.perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="onChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import vSelect from "vue-select";
import CommonServices from "@/apiServices/CommonServices";
import { FILESURL } from "@/config";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import AgentServices from "@/apiServices/AgentServices";
import store from "@/store";
import moment from "moment";
import CounselorServices from "@/apiServices/CounselorServices";
import { UserService } from "@/apiServices/storageService";
import OperationsServices from "@/apiServices/OperationsServices";
import AccountsServices from "@/apiServices/AccountsServices";
import OperationsManagerServices from "@/apiServices/OperationsManagerServices";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormSelectOption,
    flatPickr,
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        let user_type = store.getters["user/getUserDetails"].user_type;
        if (user_type === 'operations_manager') {
          this.getAllOpCounselors()
          this.getAllOperations()
          this.getAllVisa()
          this.getAllFranchises()

        }

        let temp_arr = this.tableColumns;

        if (user_type === "counselor") {
          this.getAllFranchises()
          this.getAllAssociates()
          let col_obj = {
            key: "assigned_staff",
            label: "Applications",
            sortable: false,
          };

          if (!temp_arr.some((col) => col.key === col_obj.key)) {
            temp_arr.push(col_obj);
          }

          this.tableColumns = [...this.tableColumns, ...this.sourceColumns];
        }
        else if (["operations", "visa", "operations_manager"].includes(user_type)) {
          this.getAllFranchises()
          this.getAllAssociates()
          let col_obj = {
            key: "assigned_staff",
            label: "Counselor",
            sortable: false,
          };

          if (!temp_arr.some((col) => col.key === col_obj.key)) {
            temp_arr.push(col_obj);
          }

          this.tableColumns = [...this.tableColumns, ...this.sourceColumns];
          if (user_type === "operations_manager") {
            let operations_col = {
              key: "operations_name",
              label: "Operations",
              sortable: false,
            };
            let visa_col = {
              key: "visa_name",
              label: "Visa",
              sortable: false,
            };
            if (
              !temp_arr.some((col) => col.key === operations_col.key)
            ) {
              temp_arr.push(operations_col);
            }
            if (
              !temp_arr.some((col) => col.key === visa_col.key)
            ) {
              temp_arr.push(visa_col);
            }
            this.tableColumns = [...temp_arr, ...this.sourceColumns];
          }

          this.getAllCounselors();
        }
        else if (["franchise"].includes(user_type)) {
          this.tableColumns = [...this.tableColumns];
        }
        else if (user_type === "accounts") {
          let university_commission_col = {
            key: "university_commission",
            label: "University Commission",
            sortable: false,
          };
          let franchise_commission_col = {
            key: "franchise_commission",
            label: "Franchise Commission",
            sortable: false,
          };
          if (
            !temp_arr.some((col) => col.key === university_commission_col.key)
          ) {
            temp_arr.push(university_commission_col);
          }
          if (
            !temp_arr.some((col) => col.key === franchise_commission_col.key)
          ) {
            temp_arr.push(franchise_commission_col);
          }
          this.tableColumns = [...temp_arr, ...this.sourceColumns];
        }


        return user_type;
      } else {
        return null;
      }
    },
  },
  watch: {
    user_type(newValue, oldValue) {
      if (this.user_type === "counselor") {
        this.getAllLeadSourceOptions();
      }
    },
  },
  data() {
    return {
      incr: 0,
      FILESURL,
      searchQuery: null,
      creator_user_id: null,
      studentsList: [],
      fullStudentList: [],
      totalStudentCount: 0,
      student_source: [],
      leadType: null,
      leadSource: null,
      leadTypeOptions: [
        { label: "Direct", value: "direct" },
        { label: "Associate", value: "agent" },
        { label: "Franchise", value: "franchise" },
      ],
      leadSourceOptions: [],

      leadDirectSourceOptions: [],
      leadAgentSourceOptions: [],
      leadFranchiseSourceOptions: [],
      // leadAgentSourceOptions: [],
      sourceColumns: [
        {
          key: "student_source_type",
          label: "Source Type",
          sortable: false,
        },
        { key: "student_source", label: "Source", sortable: false },
      ],
      tableColumns: [
        { key: "user_id", label: "Id", sortable: false },
        { key: "user_name", label: "Name", sortable: false },
        { key: "user_mobile", label: "Phone", sortable: false },
        { key: "student_applications", label: "Applications", sortable: false },
        {
          key: "active_applications",
          label: "Active Applications",
          sortable: false,
        },
        { key: "student_status", label: "Status", sortable: false },

        { key: "conversion_date", label: "Conversion", sortable: false },

        // {
        //   key: "student_source_type",
        //   label: "Source Type",
        //   sortable: false,
        // },
        // { key: "student_source", label: "Source", sortable: false },
        // { key: "creator", label: "Creator", sortable: false },
        // { key: "actions", label: "Actions", sortable: false },
      ],
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
      },
      student_status_options: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
        { label: "Hold", value: "hold" },
        { label: "Joined", value: "joined" },
      ],
      status: [
        { label: "Active", value: "Y" },
        { label: "Unactive", value: "N" },
      ],
      conversion_status_options: [
        { label: "Converted", value: "Y" },
        { label: "Not Converted", value: "N" },
      ],
      counselor_options: [],
      student_form: {
        email: null,
        password: null,
        firstname: null,
        lastname: null,
        mobile: null,
      },
      filters: {
        student_status: null,
        conversion_status: null,
        counselor_id: null,
        search: null,
        conversion: null,
        operations_id: null,
        visa_id: null,
        franchise_id:null,
        agent_id:null,
        service_id:null
      },
      university_commission: null,
      franchise_commission: null,
      passwordFieldType: "password",
      required,
      email,
      dateRange: "",
      debounce: null,
      op_counselors: [],
      op_operations: [],
      op_franchises:[],
      op_agents:[],
      op_visa: [],
      services_list:[],


    };
  },
  methods: {
    row_clicked(e) {
      this.$router.push({
        name: "Student Details",
        params: { student_user_id: e.student_user_id },
      });
    },
    async getAllOpCounselors() {
      try {
        const response = await OperationsManagerServices.getAllCounselors();

        if (response.data.status) {
          this.op_counselors = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },
    async getAllOperations() {
      try {
        const response = await OperationsManagerServices.getAllOperations();

        if (response.data.status) {
          this.op_operations = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },
    async getAllFranchises() {
      try {
        const response = await CommonServices.getAllFranchises();

        if (response.data.status) {
          this.op_franchises = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },
    async getAllAssociates() {
      try {
        const response = await CommonServices.getAllAssociates();

        if (response.data.status) {
          this.op_agents = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },
    async getAllVisa() {
      try {
        const response = await OperationsManagerServices.getAllVisa();

        if (response.data.status) {
          this.op_visa = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },
    getLeadData() {
      return {
        leadType: this.leadType,
        leadSource: this.leadType == "direct" ? this.leadSource : null,
        leadSourceUserId: this.leadType !== "direct" ? this.leadSource : null,
      };
    },
    getNameFromUserId(sourceType, id) {
      if (!sourceType && !id) return "-";
      const currentSources = this.getCurrentSourceOptions(sourceType);
      const currentSource = currentSources.find(
        (source) => source.value === id
      );

      return currentSource?.label;
    },
    getLeadTypeFromKey(key) {
      if (!key) return "-";
      const currentLeadType = this.leadTypeOptions.find(
        (leadType) => leadType.value === key
      );

      return currentLeadType.label;
    },
    getDirectSourceByValue(value) {
      if (!value) return "-";
      const directLeadType = this.leadDirectSourceOptions.find(
        (directLeadSource) => directLeadSource.value === value
      );

      if (!directLeadType) return "-";

      return directLeadType.label;
    },
    getDateRangeParams() {
      if (this.$route.query.dateRange) {
        this.dateRange = this.$route.query.dateRange;
      }
    },
    getSourceUser(user_id) {
      return user_id;
    },
    async getAllLeadSourceOptions() {
      this.leadDirectSourceOptions = [
        {
          label: "Facebook",
          value: "facebook",
        },
        {
          label: "Instagram",
          value: "instagram",
        },
        {
          label: "Aliff Website",
          value: "aliffWebsite",
        },
        {
          label: "Alumni",
          value: "alumni",
        },
      ];

      if (this.user_type === "counselor") {
        let res = await CounselorServices.getAssignedAgents({
          leadUserTypes: ["agent", "franchise"],
        });
        const allOptions = res.data.data;
        this.leadAgentSourceOptions = [];
        this.leadFranchiseSourceOptions = [];

        for (let option of allOptions) {
          if (option.user_type_key === "agent") {
            this.leadAgentSourceOptions.push({
              label: option.user_firstname + " " + option.user_lastname,
              value: option.user_id,
            });
          } else if (option.user_type_key === "franchise") {
            this.leadFranchiseSourceOptions.push({
              label: option.user_firstname + " " + option.user_lastname,
              value: option.user_id,
            });
          }
        }
      }

      if (this.user_type === "operations" || this.user_type === "operations_manager") {
        let res = await OperationsServices.getLeads();

        const allOptions = res.data.data;

        this.leadAgentSourceOptions = [];
        this.leadFranchiseSourceOptions = [];

        for (let option of allOptions) {
          if (option.user_type_key === "agent") {
            this.leadAgentSourceOptions.push({
              label: option.user_firstname + " " + option.user_lastname,
              value: option.user_id,
            });
          } else if (option.user_type_key === "franchise") {
            this.leadFranchiseSourceOptions.push({
              label: option.user_firstname + " " + option.user_lastname,
              value: option.user_id,
            });
          }
        }
      }
    },
    getCurrentSourceOptions(sourceType) {
      if (sourceType == "direct") {
        return this.leadDirectSourceOptions;
      } else if (sourceType == "agent") {
        return this.leadAgentSourceOptions;
      } else if (sourceType == "franchise") {
        return this.leadFranchiseSourceOptions;
      }
      return [];
    },

    conversion_date_fn(date) {
      if (date && moment(date).isValid()) {
        return moment(date).format("DD-MM-YYYY");
      } else {
        return "No";
      }
    },
    async setStudentStatus(status, student_profile) {
      try {
        const response = await CommonServices.setStudentStatus({
          student_user_id: student_profile.student_user_id,
          status,
        });
        if (response.data.status) {
          student_profile.is_close = status;
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Status Changed",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.getAssignedStudentsList();
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Status not Changed",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        this.getAssignedStudentsList();
        console.error("Error in setStudentStatus ", err);
      }
    },
    onChange() {
      this.$nextTick(() => {
        this.getAssignedStudentsList();
      });
    },
    onSearch() {
      this.pagination.currentPage = 1;
      if (this.debounce) {
        clearTimeout(this.debouce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.onChange();
      }, 1000);
    },
    onType(id, commission, franchise) {
      if (this.debounce) {
        clearTimeout(this.debouce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.saveUniAndFranchiseCom(id, commission, franchise);
      }, 2000);
    },

    async getAssignedStudentsList() {
      try {
        const response = await CommonServices.getAssignedStudents({
          ...this.filters,
          currentPage: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          lead: this.getLeadData(),
          dateRange: this.dateRange,
        });
        if (response.data.status) {
          this.studentsList = response.data.data.data;
          this.fullStudentList = response.data.data.data;
          this.totalStudentCount = response.data.data.count;
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Error fetching Students",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.error("Error getting assigned students ", error);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error fetching Students",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },
    async saveUniAndFranchiseCom(id, commission, franchise) {
      const obj = {
        university_commission: commission,
        franchise_commission: franchise,
        student_user_id: id,
      };
      const res = await AccountsServices.saveCommissionInStudent(obj);
      if (res.data.status) {
        this.getAssignedStudentsList()
      }
    },
    async getAllCounselors() {
      try {
        const response = await OperationsServices.getAllCounselors();

        if (response.data.status) {
          this.counselor_options = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },
    // setStudentSourceFilter() {
    //   this.student_source = [{ label: "website", value: "website" }];
    //   const pushed_values = [];
    //   for (let i = 0; i < this.fullStudentList.length; i++) {
    //     const student = this.fullStudentList[i];
    //     if (student.creator_user_id) {
    //       if (!pushed_values.includes(student.creator_user_id)) {
    //         pushed_values.push(student.creator_user_id);
    //         this.student_source.push({
    //           label: `${student.creator_firstname} ${student.creator_lastname}`,
    //           value: student.creator_user_id,
    //         });
    //       }
    //     }
    //   }
    // },
    // onSearch() {
    //   this.studentsList = this.fullStudentList.filter((student, index) => {
    //     let full_name = student.user_firstname + " " + student.user_lastname;
    //     let text = this.searchQuery;
    //     let text_search = true;

    //     if (text) {
    //       text_search =
    //         full_name.toLowerCase().includes(text.toLowerCase()) ||
    //         student.user_email.toLowerCase().includes(text.toLowerCase()) ||
    //         student.user_id
    //           .toString()
    //           .toLowerCase()
    //           .includes(text.toLowerCase()) ||
    //         student.user_mobile.toLowerCase().includes(text.toLowerCase());
    //     }
    //     let source = true;
    //     if (this.creator_user_id) {
    //       if (this.creator_user_id === "website") {
    //         source = student.creator_user_id === null ? true : false;
    //       } else if (!isNaN(this.creator_user_id)) {
    //         source =
    //           student.creator_user_id === this.creator_user_id ? true : false;
    //       }
    //     }
    //     let conversion = true;
    //     if (this.filters.conversion_status) {
    //       if (this.filters.conversion_status === "Y") {
    //         if (student.is_converted && student.is_converted === "Y") {
    //           conversion = true;
    //         } else {
    //           conversion = false;
    //         }
    //       } else if (this.filters.conversion_status === "N") {
    //         if (!student.is_converted || student.is_converted === "N") {
    //           conversion = true;
    //         } else {
    //           conversion = false;
    //         }
    //       }
    //     }
    //     return text_search && source && conversion;
    //   });
    // },

    student_applications_count(val) {
      if (val && val.length) {
        return val.split(",").length;
      } else {
        return 0;
      }
    },
    resetFilters() {
      this.filters = {
        student_status: null,
        conversion_status: null,
      };
      this.leadType = null;
      this.leadSource = null;
      this.searchQuery = "";
      this.$router.push("/student_list");
      this.dateRange = "";
      this.getAssignedStudentsList();
    },
    resetAddStudentModal() {
      this.student_form = {
        email: null,
        password: null,
        firstname: null,
        lastname: null,
        mobile: null,
      };
    },
    async addStudent(e) {
      e.preventDefault();
      this.$refs.addStudentForm.validate().then(async (success) => {
        if (success) {
          try {
            const response = await AgentServices.addStudent(this.student_form);

            if (response.data.status) {
              this.getAssignedStudentsList();
              this.$store.dispatch("user/onGetAssignedStudents");
              this.$refs["add-student-modal"].hide();
            }
          } catch (err) {
            console.error("Error in Adding Student ", err);
          }
        }
      });
    },
    closeModal() {
      this.$refs["add-student-modal"].hide();
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    async saveLead(user_id, applicationLimit, leadType, leadSource) {
      try {
        const response = await CounselorServices.saveProfileExtraInfo({
          application_limit: applicationLimit,
          student_user_id: user_id,
          source_type: leadType,
          source: leadType && leadType == "direct" ? leadSource : null,
          source_user_id: leadType && leadType != "direct" ? leadSource : null,
        });
        this.getAssignedStudentsList();
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Lead Option Saved",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        console.error("Error in saving application limit  ", err);
      }
    },
    async getServicesAndPlans(){
      const res = await CommonServices.getServicesAndPlans()
      if(res.data.status){
        this.services_list = res.data.data
      }
    }
  },
  beforeMount() {
    this.getDateRangeParams();
    this.getServicesAndPlans();
    this.getAssignedStudentsList();
    this.getAllLeadSourceOptions();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
