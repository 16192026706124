import axiosInstance from "./Api";

export default {
  async getHomeData() {
    return await axiosInstance().get(`/operations/home-data`);
  },

  async setDocumentStatus(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().post(`/operations/student-document-status/${student_user_id}`, payload);
  },

  async setVisaDocumentStatus(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().post(`/operations/student-visa-document-status/${student_user_id}`, payload);
  },

  async getStudentCounselorChat(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().get(`/operations/student-counselor-chat/${student_user_id}`);
  },

  async setApplicationStatus(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().post(`/operations/student-application-status/${student_user_id}`, payload);
  },

  async setApplicationSubStatus(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().post(`/operations/student-application-sub-status/${student_user_id}`, payload);
  },

  async setApplicationExtraInfo(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().post(`/operations/student-application-extrainfo/${student_user_id}`, payload);
  },

  async updateApplication(payload) {
    const { student_user_id } = payload;

    return await axiosInstance().post(`/operations/student-application/${student_user_id}`, payload);
  },

  async getLeads() {
    return await axiosInstance().get(`/operations/leads-source`);
  },

  async saveVisaChecklistInfo(payload) {
    const { student_user_id } = payload;
    return await axiosInstance().post(`/operations/save-visa-checklist-info/${student_user_id}`, payload);
  },

  async getAllCounselors() {
    return await axiosInstance().get(`/operations/all-counselors`);
  },

  async saveUniversityPayment(payload) {
    return await axiosInstance().post(`/operations/university-payment`, payload);
  },

  async cancelUniversityPayment(up_id) {
    return await axiosInstance().post(`/operations/cancel-university-payment/${up_id}`);
  },
};
